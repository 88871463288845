<template src="../../templates/instrumentPage/history.html"></template>

<script>
import Editor from '@tinymce/tinymce-vue';
import Button from '@f/Button';
import {saveCyborg, uploadImageTinymce} from '@api/cyborg';
import extractEnvironmentValuesMixin from '@/mixins/extractEnvironmentValuesMixin';

export default {
    components: {
        Editor,
        Button
    },
    mixins: [
        extractEnvironmentValuesMixin,
    ],
    props: {
        id: {
            type: Number,
            default: 0
        },
        checkPermission: {
            type: Boolean,
            default: false
        },
        data: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            editState: false,
            text: ''
        };
    },
    created() {
        this.text = this.data;
    },
    methods: {
        editStateFunction() {
            this.editState = !this.editState;
        },
        async save() {
            this.editState = !this.editState;
            this.message = await saveCyborg(
                {
                    id: this.id,
                    history_change: this.text,
                    ref: 'input'
                }
            );
        },

        async imageUploadHandler(blobInfo, success, failure, progress) {
            const sendImage = await uploadImageTinymce(blobInfo, 'cyborg');
            if (sendImage.status === 'success') {
                success(sendImage.data);
            } else {
                failure(sendImage.data);
            }
        }
    }
};
</script>
