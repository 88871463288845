<template src="../../templates/instrumentPage/technical-description.html"></template>

<script>
export default {
    props: {
        data: {
            type: String,
            default: ''
        }
    }
};
</script>
