<template src='./templates/instrument-page.html'></template>

<script>
import './styles/instrumentPage.scss';

import Tabs from '@c/Tabs';
import Button from '@f/Button';
import {getCyborgInstrument, getCyborgPermission} from '@api/cyborg';
import PageTitle from '../../components/PageTitle';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageHeader from '../../components/Header';
import ModalForm from './components/modal-form-offers';

import TabDescription from './components/instrumentPage/description';
import TabHistory from './components/instrumentPage/history';
import TabMyOffers from './components/instrumentPage/my-offers';
import TabOffers from './components/instrumentPage/offers';
import TabTechnicalDescription from './components/instrumentPage/technical-description';

export default {
    components: {
        PageTitle,
        PageHeader,
        Breadcrumbs,
        Button,
        ModalForm,
        Tabs,
        TabDescription,
        TabHistory,
        TabMyOffers,
        TabOffers,
        TabTechnicalDescription,
    },
    props: {
        id: {
            type: Number,
            default() {
                return null;
            }
        }
    },
    data() {
        return {
            checkPermission: false,
            instrumentData: {},
            showModalForm: false,
            childInstruments: []
        };
    },
    computed: {
        breadcrumbs() {
            const breadcrumbs = [
                {title: 'Киборг', href: '/cyborg'}
            ];
            if (Object.keys(this.instrumentData).length) {
                if (!!this.instrumentData.parent && !!this.instrumentData.parent.id) {
                    breadcrumbs.push({
                        title: this.instrumentData.parent.name,
                        href: `/cyborg/${this.instrumentData.parent.id}`
                    });
                }
                breadcrumbs.push({title: `Инструмент - ${this.instrumentData.name}`});
            }
            return breadcrumbs;
        }
    },
    async created() {
        this.checkPermission = await getCyborgPermission();
        this.instrumentData = await getCyborgInstrument({id: this.id});
    },
    methods: {
        openFormOffers() {
            this.showModalForm = !this.showModalForm;
        },
        async save() {
            console.log('save');
        },
    },
};
</script>
