<div class='card-page'>
    <PageHeader>
        <div class='row c'>
            <Breadcrumbs :items='breadcrumbs'></Breadcrumbs>
        </div>
        <div class="form-row px-3 mb-3 justify-content-between align-items-center">
            <PageTitle :title='Object.keys(instrumentData).length ? "Инструмент - " + instrumentData.name : ""' classWrap="col-10"></PageTitle>
            <Button :attributes="{'class':['form-ii','btn-green']}" class="mx-2 align-items-center" @click="openFormOffers" label="Добавить"></Button>
        </div>

        <div v-if="!!instrumentData && !!instrumentData.children && instrumentData.children.length > 0" class="child-instruments px-5 mb-1">
            <div v-for="instrument in instrumentData.children" class="child-instruments__link">
                <a :href="`/cyborg/${instrument.id}`">{{instrument.name}}</a>
            </div>
        </div>
    </PageHeader>

    <Tabs
		class="px-5"
        header-class="tabs-header_borders-top-bottom"
        :columns="[
            {
                id: 'description',
                label: 'Описание',
                slot_name: 'description'
            },
            {
                id: 'technicalDescription',
                label: 'Техническое описание',
                slot_name: 'technicalDescription',
            },
            {
                id: 'offers',
                label: 'Предложения',
                slot_name: 'offers',
            },
            {
                id: 'history',
                label: 'История изменений',
                slot_name: 'history',
            },
            {
                id: 'myOffers',
                label: 'Мои предложения',
                slot_name: 'myOffers',
            }
        ]"
        :data="{
            description: {
                content: ''
            },
            technicalDescription: {
                content: ''
            },
            offers: {
                content: ''
            },
            history: {
                content: ''
            },
            myOffers: {
                content: ''
            }
        }"
        selectedTab="offers"
    >
        <template v-slot:description="row">
            <TabDescription
                :id="id"
                v-if="Object.keys(instrumentData).length"
                :data="instrumentData.text ? instrumentData.text : ''"
                :checkPermission="checkPermission"></TabDescription>
        </template>

        <template v-slot:technicalDescription="row">
            <TabTechnicalDescription data="Техническое описание"></TabTechnicalDescription>
        </template>

        <template v-slot:offers="row">
            <TabOffers data="Предложения"></TabOffers>
        </template>

        <template v-slot:history="row">
            <TabHistory
                :id="id"
                v-if="Object.keys(instrumentData).length"
                :data="instrumentData.history_change ? instrumentData.history_change : ''"
                :checkPermission="checkPermission"></TabHistory>
        </template>

        <template v-slot:myOffers="row">
            <TabMyOffers data="Мои предложения"></TabMyOffers>
        </template>
    </Tabs>

    <ModalForm v-if="showModalForm" :toggleModalHandler="openFormOffers" @save="save" />
</div>
