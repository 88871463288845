<template src="./templates/button.html"></template>

<script>
import Button from '@f/Button';

export default {
    components: {
        Button,
    },
    props: {
        id: {
            type: Number,
            default() {
                return 0;
            }
        },
        type: {
            type: String,
            default() {
                return '';
            }
        },
        value: {
            type: Number,
            default() {
                return 0;
            }
        },
        classValue: {
            type: String,
            default() {
                return '';
            }
        },
        handler: {
            type: Function,
            default() {
                return () => {};
            }
        },
        isDisabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
    }
};
</script>

<style scoped>

</style>
