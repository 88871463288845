<div>
    <div v-if="!editState">
        <div v-if="!!checkPermission">
            <Button @click="editStateFunction"
                label='Редактировать историю изменений ✎'
                :attributes="{ class: 'btn-green mb-3' }"></Button>
        </div>
        <div class="text-editor" v-html="text"></div>
    </div>
    <div v-else>
        <Editor
            :api-key="tinyMCEApiKey"
            :init="{
                menubar: true,
                relative_urls: true,
                convert_urls: false,
                statusbar: true,
                height: 500,
                image_title: true,
                file_picker_types: 'image media',
                images_reuse_filename: true,
                automatic_uploads: true,
                images_file_types: 'jpg,svg,webp,jpeg,png',
                images_upload_handler: imageUploadHandler,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                plugins: [
                    'advlist autolink lists link image imagetools charmap print preview anchor',
                    'media table paste code'
                ],
                // menubar: 'insert',
                toolbar:
                    'removeformat selectall code | \
                    link undo redo | bold italic backcolor fontselect fontsizeselect formatselect | \
                    image insertfile | \
                    alignleft aligncenter alignright alignjustify alignnone | \
                    bullist numlist outdent indent link',
                language: 'ru',
                fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
            }"
            v-model="text"
        ></Editor>
        <Button @click="save"
            label='Сохранить'
            :attributes="{ class: 'btn-green mt-3 ml-auto d-block' }"></Button>
    </div>
</div>
