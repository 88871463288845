<template src="./templates/vote.html"></template>

<script>
import './styles/vote.scss';
import ButtonVote from './button';

export default {
    props: {
        item: {
            type: Object,
            default() {
                return {};
            }
        },
        vote: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    components: {
        ButtonVote
    },
    methods: {
        async voteHandler(event, vote, itemId) {
            // console.log(event, vote, itemId);
            const ADD_LIKE = 'ADD_LIKE';
            const REMOVE_LIKE = 'REMOVE_LIKE';
            const ADD_DISLIKE = 'ADD_DISLIKE';
            const REMOVE_DISLIKE = 'REMOVE_DISLIKE';
            const ADD_LIKE_REMOVE_DISLIKE = 'ADD_LIKE_REMOVE_DISLIKE';
            const ADD_DISLIKE_REMOVE_LIKE = 'ADD_DISLIKE_REMOVE_LIKE';

            event.preventDefault();
        }
    }
};
</script>
