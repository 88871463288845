<template>
	<teleport to="body">
			<Modal v-show="visible"
						 :close-button="true"
						 :buttons="[
									{
											label: !data.id ? 'Добавить' : 'Сохранить',
											event: 'submitForm',
											class: 'btn-green h36 inline'
									}
							]"
						 :template-buttons="{class: 'modal-section mx-0 modal-buttons-p'}"
						 @onClose="toggleModalHandler"
						 @submitForm="submitForm">

					<template #header>
							<h2>{{ !data.id ? 'Предложить улучшение' : 'Редактировать инструмент' }}</h2>
					</template>

					<template #body>
						<form id="login-form" action="#" class="ph-3 flex-direction-column">
									<div class="row-col">
											<div class="col">
												<div class="card--header"><img src="https://via.placeholder.com/48x48" alt="">
													<div>
														<div class="card--header-name"> Попов Иван Олегович </div>
														<div class="card--header-description"> Директор филиальной сети </div>
													</div>
												</div>
											</div>
									</div>
									<div class="row-col">
											<div class="col">
													<Input
															v-model="data.name"
															label="Название*"
															:attributes="{name:'name',type:'text',placeholder:'Краткое пояснение улучшения'}"
													></Input>
											</div>
									</div>
									<div class="row-col mt-1">
											<div class="col">
													<Select
															v-model="data.parent"
															:options="data.parents"
															label="Инструмент"
													></Select>
											</div>
									</div>
									<div class="row-col mt-1">
										<div class="col">
												<Textarea
														label="Описание*"
												></Textarea>
										</div>
								</div>
								<div class="mt-1">
									*поля, обязательные для заполнения
								</div>
							</form>
					</template>
			</Modal>
	</teleport>

	<ModalStatus v-if="showModalStatus" :message="message"></ModalStatus>
</template>
