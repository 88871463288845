<template src="../templates/modal-status.html" />

<script>
import Modal from '@c/Modal';

export default {
    name: 'ModalStatus',
    components: {
        Modal
    },
    props: {
        toggleModalHandler: {
            type: Function,
            default: () => {}
        },
        message: {
            type: String,
            default: ''
        }
    },
};
</script>
