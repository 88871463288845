<div class="vote">
  <div>
    <ButtonVote :handler="voteHandler" type="like" :value="vote.like" :isDisabled="vote.disabled"
      classValue="btn-green" />

    <ButtonVote :handler="voteHandler" type="dislike" :value="vote.dislike" :isDisabled="vote.disabled"
      classValue="btn-red" />
  </div>

  <ButtonVote :handler="voteHandler" type="views" :value="vote.views" :isDisabled="vote.disabled"
    classValue="bg-light-blue" />
</div>