import api from '../tools/api';

export const getCyborgPermission = formData => api.request(
    'cyborg',
    'get-cyborg-permission',
    {...formData}
);
export const getCyborg = formData => api.request(
    'cyborg',
    'get-cyborg',
    {...formData}
);
export const getCyborgForm = id => api.request(
    'cyborg',
    'get-cyborg-form',
    {...id}
);
export const getCyborgInstruments = () => api.request(
    'cyborg',
    'get-cyborg-instruments'
);
export const getCyborgStatuses = () => api.request(
    'cyborg',
    'get-cyborg-statuses'
);
export const getCyborgUsersTypes = () => api.request(
    'cyborg',
    'get-cyborg-users-type'
);
export const getCyborgInstrumentRelation = () => api.request(
    'cyborg',
    'get-cyborg-instrument-relation'
);

export const saveCyborg = formData => api.request(
    'cyborg',
    'save-cyborg',
    {...formData}
);

export const getCyborgInstrument = id => api.request(
    'cyborg',
    'get-cyborg-instrument',
    {...id}
);

export const uploadImageTinymce = (blob, path) => api.uploadImage(
    blob,
    path
);
