<template src="../../templates/instrumentPage/offers.html"></template>

<script>
import Vote from '@c/Vote';
import Button from '@f/Button';

export default {
    components: {
        Vote,
        Button,
    },
    props: {
        data: {
            type: String,
            default: ''
        }
    },
};
</script>

<style lang="scss">
    .card {
        &--list {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
						gap: 20px;
						margin-top: 16px;
        }

        &--wrap {
            // display: flex;
            // justify-content: space-between;
            // align-items: stretch;
            // flex-direction: column;
            flex: 0 1 calc((100% / 4) - 15px);
        }

        &--item {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;

            border: 1px solid rgba(55, 53, 47, 0.16);
            border-radius: 3px;

            height: 100%;
            padding: 24px;

            &.general {
                border-color: #e9a800;

                .card--badge-react {
                    background-color: #eb5757;
                    color: #ffffff;
                }
            }
        }

        &--badge {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-bottom: 8px;
						gap: 10px;
        }

        &--badge-react {
            border-radius: 4px;
            padding: 4px 8px;

            background-color: #f7f6f3;
            color: #37352f;
            white-space: nowrap;
            font-weight: 500;
            font-size: 13px;
            line-height: 140%;
        }

        &--badge-stage {
            color: rgba(0, 0, 0, 0.6);
            white-space: nowrap;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 140%;
        }

        &--header {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            margin-bottom: 16px;

            img {
                margin-right: 8px;
                border-radius: 50%;
            }
        }

        &--header-name {
            color: #37352f;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 140%;
        }

        &--header-description {
            color: #37352f;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 140%;
        }

        &--title {
            margin-bottom: 8px;

            color: #37352f;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 140%;
        }

        &--text {
            margin-bottom: 16px;

            color: #37352f;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 140%;
        }

        &--info-date {
            margin-bottom: 24px;

            color: #37352f;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 140%;
        }

        &--info-status {
        }
    }
</style>
